<template>
  <div>
    <v-container pb-0 pt-2>
      <page-title title="訂單紀錄" />
    </v-container>

    <liff-list :listKey="listKey">
    </liff-list>
  </div>
</template>

<script lang="babel" type="text/babel">
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./orderList"

export default {
  mixins: [listMixin],
  components: {

  },
  data: () => ({
    listKey: 'liff-store-order-list',
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$route.params.providerId;
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    status() {
      if(!this.listFilter) return null
      return this.listFilter.status
    },
    title() {
      if(!this.status) return ''
      return this.$t(`provider.status.${this.status}`)
    },
  },
  mounted() {
    this.$root.gaLogEvent('商店_訂單紀錄頁')
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.orderApi.index(this.storeId, this.providerId, params)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
