export default [
    // 付款方式
    {
        text: "全部訂單",
        value: ""
    },
    {
        text: "新訂單",
        value: "created"
    },
    {
        text: "已接單",
        value: "apply"
    },
    {
        text: "出貨中",
        value: "sended"
    },
    {
        text: "已完成",
        value: "finished"
    },
    {
        text: "取消",
        value: "canceled"
    },
    {
        text: "退貨申請",
        value: "request_return"
    },
]